// seabeescuit-custom-form
jQuery(document).ready(function ($) {
    jQuery(shortcodeOpts.form_query_selector).submit(function (event) {
        event.preventDefault();
        jQuery('#response_success').addClass('d-none');
        jQuery('#response_warning').addClass('d-none');
        jQuery('#response_error').addClass('d-none');

        const asrecaptcha = jQuery(this).data('asrecaptcha');
        const gtoken = jQuery(this).data('grecaptcha-token');

        // console.log('submit form');

        let continua = true;
        if (asrecaptcha) {
            continua = false;

            if (gtoken) {
                const isValidToken = verifyToken(gtoken);
                if (isValidToken) {
                    continua = true;
                }
            }
            if (!continua) {
                jQuery('#response_warning').html('<small>Assicurati di aver passato il controllo antispam</small>');
                jQuery('#response_warning').removeClass('d-none');
            }
        }

        if (continua) {
            jQuery('#this-is-the-loader').removeClass('d-none');
            var data = {
                'action': shortcodeOpts.action,
                'form_id': shortcodeOpts.wp_form_id,
                'operation': shortcodeOpts.operation,
                'security': shortcodeOpts.ajax_nonce,
                'form_data': jQuery(this).serializeArray()
            };

            jQuery.post(shortcodeOpts.ajaxurl, data, function (response) {
                // console.log('Got this from the server: ', response);
                jQuery('#this-is-the-loader').addClass('d-none');
                if (response.result) {
                    if (shortcodeOpts.thank_you_page_permalink) {
                        if (shortcodeOpts.thank_you_page_permalink.length > 0) {
                            window.location = shortcodeOpts.thank_you_page_permalink;
                        }
                    } else {
                        jQuery('#response_success').html('<small>EMAIL INVIATA CON SUCCESSO</small>');
                        jQuery('#response_success').removeClass('d-none');
                    }
                } else {
                    jQuery('#response_error').html('<small>EMAIL NON INVIATA</small>');
                    jQuery('#response_error').removeClass('d-none');
                }
            });
        }
    });

});